import router from '@/router';
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { dateDiff, checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class TermListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'ALL', text:'전체' },
        { id: 'ING', text:'진행중' },
        { id: 'PLAN', text:'예약' },
        { id: 'END', text:'만료/중단' },
      ],
      option:{
        isCount: false,
        queryKeyName:'status'
      },
      value:'ALL'
    }
    this.promotionData = {
      boardData : {
        title:'전체 목록',
        drawDataList: [
          {
            title:'No.',
            width:'60px',
            value:'id',
            isSorting: true,
            isAlignLeft: true
          },
          {
            title:'코드입력기한',
            width:'188px',
            value:'',
            dateRangeValue:{
              startValue:'start',
              endValue:'end',
              betweenText:' ~ ',
              isEndPastDateToRed:true
            },
            isEllip: true,
            isAlignLeft: true
          },
          // {
          //   title:'상태',
          //   width:'74px',
          //   value:'status',
          //   filter: { name:'convertIdToText', value:'promotion_status' },
          //   badge: { badgeColorFilter:{ name:'convertIdToColor', dataListName:'promotion_status' }, badgeFill: false }
          // },
          {
            title:'코드이름',
            width:'160px',
            value:'name',
            class:{
              name:'td_point'
            },
            isAlignLeft: true
          },
          {
            title:'쿠폰금액',
            width:'',
            value:'point',
            valueCustom:[
              {
                type: 'value',
                value:'point',
                filter:{
                  name:'convertNumberToComma',
                  value2:'0'
                },
              },
              { 
                type: 'text',
                value: '원'
              },
            ],
            isAlignLeft: true
          },
          {
            title:'한도',
            width:'70px',
            value:'maxCount',
            valueCustom:[
              {
                type: 'value',
                value:'maxCount',
                filter:{
                  name:'convertNumberToComma',
                  value2:'0'
                },
                ifNot:{
                  value:'maxCount',
                  condition:0
                }
              },
              {
                type: 'text',
                value: '개',
                ifNot:{
                  value:'maxCount',
                  condition:0
                }
              },
              { 
                type: 'text',
                value: '한도없음',
                if:{
                  value:'maxCount',
                  condition:0
                }
              },
            ],
            isAlignLeft: true
          },
          {
            title:'쿠폰발행(등록)',
            width:'',
            value:'register_count',
            valueCustom:[
              {
                type: 'value',
                value:'register_count',
                filter:{
                  name:'convertNumberToComma',
                  value2:'0'
                },
              },
              { 
                type: 'text',
                value: '건'
              },
            ],
            isAlignLeft: true,
            isEllip: true
          },
          {
            title:'쿠폰사용',
            width:'',
            value:'use_count',
            valueCustom:[
              {
                type: 'value',
                value:'use_count',
                filter:{
                  name:'convertPersent',
                  value:{
                    rowData:'register_count'
                  },
                  value2: 'floor',
                },
              },
              { 
                type: 'text',
                value: '%'
              },
            ],
            isAlignLeft: true
          },
          {
            title:'총 보상금액',
            width:'',
            value:'total_price',
            valueCustom:[
              {
                type: 'value',
                value:'total_price',
                filter:{
                  name:'convertNumberToComma',
                  value2:'0'
                },
              },
              { 
                type: 'text',
                value: '원'
              },
            ],
            isAlignLeft: true
          },
          {
            title:'메모',
            width:'',
            value:'memo',
            emptyValueText:'-',
            isEllip: true,
            isAlignLeft: true
          },
        ],
        option:{
          isTotal: true,
          isSize: true
        }
      },
      ALL:{
        dataList: [],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      ING:{
        dataList: [],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      PLAN:{
        dataList: [],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      END:{
        dataList: [],
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      }
    };
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
  }
  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.promotionData.ALL.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.promotionData.ALL.searchParams.page = Number(page);
    }
    this.getData();
  }
  onSearch(){
    this.getData();
  }
  onUpdateTab(value){
    const tabValueText = convertIdToText(value, this.tabData.dataList)
    this.promotionData.boardData.title = `${tabValueText} 목록`
  }
  onClickAdd(){
    router.push({
      name: 'SERVICE_PROMOTION_REGISTER',
      query : {
        pageSize : this.promotionData.ALL.searchParams.pageSize,
        page : this.promotionData.ALL.searchParams.page,
      }
    });
  }
  onClickDeploy(){

  }
  onClickRow(rowId,rowData){
    router.push({
      name: 'SERVICE_PROMOTION_MODIFY',
      params: { status : this.tabData.value, id : rowId},
      query : {
        pageSize : this.promotionData.ALL.searchParams.pageSize,
        page : this.promotionData.ALL.searchParams.page,
      }
    });
  }
  getData(){
    const query = makeQueryStringByObject(this.promotionData.ALL.searchParams);
    const path = `${apiPath.COUPON_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.promotionData.ALL.dataList = resultData.data.map(item => {
          item.disabled =
            dateDiff(item.start, new Date()) > 0 ||
            Boolean(checkPastDate(item.end, 'yesIsPast'));
        return item
      });
      this.promotionData.ALL.paginationData.totalCount = resultData.total
      this.promotionData.ALL.paginationData.totalPage = resultData.totalPage
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}