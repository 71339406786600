<template>
  <PageWithLayout>
    <Tab
      :tabData="viewModel.tabData"
      :value.sync="viewModel.tabData.value"
      @update:value="value => viewModel.onUpdateTab(value)"/>
    <Board
      :boardData="viewModel.promotionData.boardData"
      :searchParams.sync="viewModel.promotionData[viewModel.tabData.value].searchParams"
      :dataList="viewModel.promotionData[viewModel.tabData.value].dataList"
      :paginationData="viewModel.promotionData[viewModel.tabData.value].paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="새 코드 발행"
          @onClickBtn="viewModel.onClickAdd()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import PromotionListViewModel from '@/views/service/promotion/viewModel/PromotionListViewModel'

export default {
  name:'PromotionList',
  components:{
    PageWithLayout,
    Tab,
    Board,
    Button,
    IconSvg,
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
  },
  data(){
    return{
      viewModel: new PromotionListViewModel(),
    }
  },
}
</script>
<style lang="scss" scoped>
.board_comm::v-deep .tbl_comm table .tc_end_red .txt_tbl span{color:$RED_COLOR}
</style>